@use "../abstracts" as *;

.picture {
    @include elevate-aside; // LOL

    width: 250px;
    display: grid;
    grid-auto-flow: row;
    row-gap: 8px;
    padding: 10px;

    background: $white-color;

    box-sizing: border-box;
    border-radius: 8px;

    & > img {
        width: 100%;
        height: 140px;

        object-fit: cover;
        border-radius: 2px;
    }

    &-actions {
        display: flex;

        & > * {
            margin-right: 4px;
        }
    }
}
