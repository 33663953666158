@use "../abstracts" as *;

.aside {
    width: 240px;

    display: flex;
    flex-flow: column;

    &-header {
        height: 70px;
        @include orange-gradient;

        display: flex;
        justify-content: center;
        align-items: center;

        & > span {
            color: $white-color;
            font-weight: 600;
            font-size: 1.125rem;
        }
    }

    &-footer {
        background-color: $white-color;
        padding: 12px 28px 34px 28px;

        // name
        b {
            cursor: pointer;
        }

        button {
            margin-top: 12px;
        }
    }

    nav {
        background-color: $white-color;
        @include elevate-aside;
    }
}
