@use "../abstracts" as *;

.ql-editor {
    img {
        max-width: 320px;
        max-height: 240px;

        border-radius: 8px;

        object-fit: cover;
    }

    // CUSTOM BLOTS
    .nbsp {
        * {
            color: red;
        }
    }
}

// CUSTOM ICONS
.ql-toolbar {
    border-radius: 8px 8px 0 0;
    background: $white-color;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.ql-container {
    @include inset-input;
    background: $white-color;
    border-radius: 0 0 8px 8px;
}
