@use "../abstracts" as *;
.gallery {
    & > ul {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;

        & > li {
            padding: 14px;
            margin: 8px;
            border-radius: 8px;
            border: 1px solid $gray-color;

            display: flex;
            flex-wrap: wrap;
            flex-flow: column;
            background-color: $light-gray-color;

            & > img {
                margin-top: 8px;
                width: 260px;
                height: 320px;
                object-fit: cover;
            }
        }
    }

    &-edit {
        display: grid;
        grid-template-columns: auto 260px;
        column-gap: 50px;
        justify-content: flex-start;

        & > img {
            height: 150px;
            width: 100%;
            object-fit: cover;
        }
    }

    &-variants {
        list-style: none;
        margin: 0;
    }

    &-variant {
        display: grid;
        flex-flow: column;
        justify-items: flex-start;
        row-gap: 20px;

        margin: 26px 0;

        &-header {
            display: flex;
            flex-flow: row;

            & > label {
                margin-right: 12px;
            }
        }

        &-body {
            padding: 10px;
            box-sizing: border-box;

            display: grid;
            grid-template-columns: auto 1fr;
            grid-template-rows: auto auto;

            border-radius: 4px;
            border: 1px solid $blue-color;
            background: $white-color;

            row-gap: 10px;
            column-gap: 20px;

            & > img {
                width: 200px;
                height: 120px;
                object-fit: cover;

                grid-column: 1/2;
                grid-row: 1/3;
            }

            & > input {
                grid-column: 2/3;
                grid-row: 1/2;

                align-self: flex-end;
            }

            & > span {
                grid-column: 2/3;
                grid-row: 2/3;
                align-self: flex-start;
            }
        }
    }
}
