@use "../abstracts" as *;

button {
    border: 0;
    border-radius: 8px;

    color: $white-color;
    font-weight: 600;
    font-size: 1rem;

    padding: 12px 24px;

    cursor: pointer;
}

button.primary {
    @include orange-gradient;
}

button.secondary {
    background-color: $gray-color;
}

button.small {
    padding: 6px 12px;
    font-size: 0.9rem;
    border-radius: 6px;
}

button.icon {
    padding: 0;
    background: none !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

button:disabled {
    opacity: 0.4;
    cursor: auto;
}
