@use "../abstracts" as *;

input,
textarea,
.content,
select {
    font-size: 0.9375rem;
    padding: 16px 18px;
    border-radius: 8px;
    border: 1px solid $gray-color;

    @include inset-input;

    background-color: $white-color;

    &.ng-invalid {
        border: 1px solid red !important;
        color: red !important;
    }
}

input {
    &::placeholder {
        color: $gray-color;
    }
}

input[type="datetime-local"] {
    width: 240px !important;
    box-sizing: border-box !important;
}

input[generated="true"] {
    opacity: 0.6;
    pointer-events: none;
    cursor: pointer;
}
