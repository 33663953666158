@use "./abstracts/" as *;

@use "./components/" as *;

@use "./layout/" as *;

* {
    margin: 0;
    padding: 0;
    // font-family: "Hind";
    font-family: "Poppins", sans-serif;
    color: $black-color;
}

*:focus {
    outline: 0;
}
