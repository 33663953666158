@use "../abstracts" as *;

a {
    text-decoration: none;

    &:visited {
        color: $black-color;
    }

    &:hover {
        font-weight: 600;
    }
}

h1 {
    font-size: 3.125rem;
    color: $orange-color;
    font-weight: 500;
}