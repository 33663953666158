@mixin elevate-aside {
    -webkit-box-shadow: 0px 0px 25px 0px rgba(31, 39, 75, 0.2);
    -moz-box-shadow: 0px 0px 25px 0px rgba(31, 39, 75, 0.2);
    box-shadow: 0px 0px 25px 0px rgba(31, 39, 75, 0.2);
}

@mixin inset-input {
    -webkit-box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
}

@mixin orange-gradient {
    background: linear-gradient(90deg, rgba(255, 115, 30, 1) 0%, rgba(255, 80, 46, 1) 100%);
}
