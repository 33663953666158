$white-color: #ffffff;
$black-color: #242424;

$dark-gray-color: #b1b1b1;
$gray-color: #dedede;
$light-gray-color: #f7f7f7;
$orange-color: #ff731e;
$blue-color: #d6d8ef;

$overlay-index: 1000;
