@use "../abstracts" as *;

.content {
  display: flex;
  flex-flow: column;
  margin-top: 60px;
  list-style-type: none;

  img {
    width: 100%;
    object-fit: contain;
  }

  button {
    background: 0;
    margin: 6px;
  }

  &-block {
    margin: 4px 0;

    border: 1px solid $gray-color;
    border-radius: 4px;
    padding: 6px;

    &.text {
      border-style: dashed;
    }

    &-image {
      display: flex;
      flex-flow: row;
      height: 200px;

      img {
        height: 100%;
        width: auto;
        max-width: 400px;
        object-fit: contain;
      }

      &-data {
        flex: 1;
        display: flex;
        flex-flow: column;
        padding: 14px;
      }
    }
  }
}
