@use "../abstracts" as *;

.overview {
    display: flex;
    flex-flow: column;

    max-width: 1200px;

    padding-bottom: 84px;

    & > h1 {
        margin: 46px 0;
    }

    &-header {
        display: flex;
        flex-flow: row;
        align-items: center;

        & > h1 {
            flex: 1;
            text-align: left;
        }
    }

    & > hr {
        margin: 50px 0;
    }

    &-back {
        font-size: 0.875rem;
        color: $gray-color;

        padding: 18px 0;
        cursor: pointer;

        border-top: 1px solid $gray-color;
        border-bottom: 1px solid $gray-color;
    }
}
