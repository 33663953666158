@use "../abstracts" as *;

.tags {
    display: flex;
    flex-flow: row;
}

.tag {
    background-color: $gray-color;
    margin-right: 6px;
    border-radius: 99px;
    border: 0.5px solid $dark-gray-color;
    display: flex;
    flex-flow: row;
    padding: 6px 10px;
    align-items: center;

    & > button {
        width: 10px;
        height: 10px;
        margin-right: 4px;
        padding: 0;
        border: 0;
        display: flex;
        background-color: transparent;

        & > img {
            width: 100%;
            height: 100%;
        }
    }

    & > span {
        font-weight: 500;
        color: $dark-gray-color;
        font-size: 0.875rem;
    }
}
