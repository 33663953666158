.gallery-picker {
    & > img {
        width: 240px;
        height: 150px;

        border-radius: 6px;

        object-fit: cover;

        &:hover {
            opacity: 0.5;
            cursor: pointer;
        }
    }
}
