.form {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: auto 1fr;
    row-gap: 24px;
    column-gap: 24px;
    align-items: center;

    &-editor {
        grid-column: 1/3;

        display: grid;
        grid-auto-flow: row;
        row-gap: 14px;

        place-items: flex-start;
    }

    &-control {
        &-grid {
            display: grid;
            grid-auto-flow: row;
            row-gap: 8px;

            // checkbox combo
            &-checkbox {
                display: flex;
                align-items: center;

                & > .checkbox {
                    margin-right: 8px;
                }
            }
        }
    }
}

.form-gallery {
    max-width: 500px;
}
