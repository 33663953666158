@use "../abstracts" as *;

.overlay {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    z-index: $overlay-index;

    display: flex;
    justify-content: center;
    align-items: center;

    &-inner {
        background-color: #f4f4f4;
        max-width: 1200px;
        max-height: 90%;

        width: 100%;
        height: 100%;

        border-radius: 24px;
        overflow: hidden;

        grid-template-rows: auto 1fr;

        &-header {
            height: 48px;
            background-color: $gray-color;
            display: flex;
            justify-content: flex-end;
        }

        &-body {
            overflow-y: scroll;
            padding: 24px;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}
