@use "../abstracts" as *;

.checkbox {
    & > input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    width: 22px;
    height: 22px;

    cursor: pointer;
    user-select: none;

    display: inline-flex;

    &-inner {
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }

    & > input ~ &-inner {
        background: $white-color;
        box-shadow: inset 0px 0px 1px 1px $blue-color;
    }

    & > input:checked ~ &-inner {
        background: $orange-color;
        box-shadow: none;
    }
}
