@use "../abstracts" as *;

.nav-link.active {
    li {
    }
}

nav {
    // align-items: center;
    // display: flex;
    // flex-flow: column;
    width: 100%;
    height: 100%;
    flex: 1.0;

    // SPECIAL UL ORDER TEST xdd
}

.navbar {
    display: flex;
    align-content: center;
    flex-flow: column;
    list-style-type: none;

    & > li {
        margin: 4px 0;
        & > a {
            display: flex;
            height: 100%;
            padding: 12px;
            padding-left: 34px;
            font-size: 1.125rem;
            text-align: left;
            border-left: 6px solid transparent;
            font-weight: 400;

            &.active {
                border-left: 6px solid $orange-color;
                background-color: $light-gray-color;
            }
            &:hover {
                font-weight: 400;
            }
        }
    }
}
