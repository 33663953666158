@use "../abstracts" as *;

.editor {
    &-toolbar {
        background-color: $light-gray-color;
        // margin: 20px 0;
        margin-top: 20px;
        border-radius: 6px;

        display: grid;
        grid-auto-flow: column;

        padding: 6px 12px;
        column-gap: 12px;

        grid-template-columns: auto auto auto 1fr;

        @include elevate-aside;

        & > button {
            padding: 0;
            margin: 0;
            display: flex;

            & > img {
                width: 28px;
                height: 28px;
            }
        }
    }
}
