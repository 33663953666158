@use "../abstracts" as *;

.dataview {
    // table-layout: fixed;
    // border-collapse: collapse;

    // tr {
    //     background-color: $white-color;
    //     height: 60px;
    //     margin: 0 8px;

    //     td:first-child {
    //         border-left-style: solid;
    //         border-top-left-radius: 8px;
    //         border-bottom-left-radius: 8px;
    //     }
    //     td:last-child {
    //         border-right-style: solid;
    //         border-bottom-right-radius: 8px;
    //         border-top-right-radius: 8px;
    //     }
    // }

    // td {
    //     border: 0;
    //     border-spacing: 0;
    //     // @include elevate-aside; // LOL
    // }

    flex-flow: column;
    display: flex;
    list-style-type: none;

    width: 100%;

    &.rows {
        & > li {
            height: 60px;
            margin: 8px 0;
            background-color: $white-color;
            @include elevate-aside; // LOL
            border-radius: 8px;

            display: flex;
            flex-flow: row;
            // padding-left: 28px;
            // padding-right: 8px;
            padding-left: 20px;
            padding-right: 8px;

            align-items: center;

            & > span:first-of-type {
                flex: 1;
                color: $orange-color;
            }

            & > span:nth-child(2) {
                width: 120px;
            }

            & > * {
                margin: 0 8px;
            }
        }
    }

    &.gallery {
        // flex-flow: row;
        // flex-wrap: wrap;
        display: grid;
        grid-template-columns: repeat(auto-fill, 250px);

        column-gap: 25px;
        row-gap: 25px;
    }
}
